import {CircularProgress, Grid, IconButton, Paper, TableBody, Typography} from "@mui/material";
import React, {useContext, useEffect, useState} from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import useTable from "../../components/hooks/useTable";
import TestResultsDialog from "../../components/dialogues/TestResultDialog";
import * as dataSetService from "../../services/dataSetService";
import * as formattingUtils from "../../utils/formattingUtils";
import * as UIUtils from "../../utils/UIUtils";
import usePagination from "../../components/hooks/usePagination";
import useAlert from "../../components/hooks/useAlert";
import ProgressDialog from "../../components/dialogues/ProgressDialog";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ProjectContext from "../../context/project-context";
import IntentOnHover from "../../components/IntentOnHover";
import ResponseOnHover from "../../components/ResponseOnHover";
import Toolbar from "../../components/Layout/ToolBar";
import '../table.css';
import Intent from "../../components/Intent";
import {UND_DIALOGUE} from "../../utils/formattingUtils";
import {ControlPoint} from "@mui/icons-material";

const columns = [
    {id: "question", label: "Dotazy", firstOnly: true, width: '300px'},
    {id: "intents", label: "Intenty"},
    {id: "confidence", label: "Confidence", width: '10%'},
    {id: "id-response", label: "Odpoved", width: '100%'},
    {
        id: "Info",
        label: (
            <InfoOutlinedIcon
                sx={{color: (theme) => theme.palette.primary.main, fontSize: "20px"}}
            />
        )
    }
];

const SetsTesting = (props) => {
    const [testResults, setTestResults] = useState([]);
    const [testReport, setTestReport] = useState(null);
    const [conflictsOnly, setConflictsOnly] = useState(false);
    const [openProgressDialog, setOpenProgressDialog] = useState(false);
    const [progress, setProgress] = useState(0);
    const [itemsSize, setItemsSize] = useState(0);
    const [infoMessages, setInfoMessages] = useState([]);
    const [openResultsDialog, setOpenResultsDialog] = useState(false);
    const [dataFetched, setDataFetched] = useState(true);
    const {TblContainer, TblHead} = useTable([], columns, true);
    const {setTotalCount, totalCount, rowsPerPage, page, resetPagination, TblPagination} = usePagination();
    const {Alert, status, showError} = useAlert();
    const {
        selectedProject,
        selectedDataSet,
        selectedClient
    } = useContext(ProjectContext);
    const [rowHeight, setRowHeight] = useState(0);
    const cellElementRef = node => {
        if (node !== null) {
            const rh = node.offsetHeight;
            if (rowHeight < rh) {
                setRowHeight(rh);
            }
            node.style.height = `${rowHeight}px`;
        }
    };

    useEffect(() => {
        props.setTitle("Testování sad");
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (selectedProject) {
            setTestResults([]);
            setTestReport(null);
            setConflictsOnly(false);
            resetPagination();
        }
        //eslint-disable-next-line
    }, [selectedProject]);


    useEffect(() => {
        if (selectedDataSet && selectedClient) {
            getHistoricalTestResults();
        }
        //eslint-disable-next-line
    }, [selectedDataSet, selectedClient, conflictsOnly, testReport, rowsPerPage, page]);

    useEffect(() => {
        if (selectedDataSet  && selectedClient) {
            resetPagination()
        }
        //eslint-disable-next-line
    }, [conflictsOnly, testReport]);

    useEffect(() => {
        if (testReport) {
            setOpenResultsDialog(true);
        }
    }, [testReport]);


    const getHistoricalTestResults = () => {
        setDataFetched(false);
        setRowHeight(0);
        dataSetService
            .getHistoricalTestResult(
                selectedDataSet,
                selectedProject.id,
                selectedClient,
                conflictsOnly,
                page,
                rowsPerPage
            )
            .then((response) => {
                const result = response.data;
                if (result) {
                    setTestResults(result.items);
                    setTotalCount(result.totalCount);
                }
            })
            .catch((error) => {
                showError(error);
            }).finally(() => {
            setDataFetched(true);
        });
    };

    const setDataSetIntent = (item) => {
        const computedProperty = item.dataSetItem.overriddenResponseId ? 'overriddenIntent' : 'intent';
        const intents = [
            {
                dataSetItemId: item.dataSetItem.id,
                testResultItemId: item.id,
                [computedProperty]: item.intents[0].intent
            }
        ]
        updateDataSet({items: intents})
    };

    const setDataSetResponseId = (item) => {
        const computedProperty = item.dataSetItem.overriddenResponseId ? 'overriddenResponseId' : 'responseId';
        const answerIds = [
            {
                dataSetItemId: item.dataSetItem.id,
                testResultItemId: item.id,
                [computedProperty]: item.answers.reduce((prevValue, currentValue) => currentValue.answerId ? currentValue.answerId : null, '')
            }
        ]
        updateDataSet({items: answerIds})
    };

    const updateDataSet = (dataSetItems) => {
        dataSetService
            .updateDataSet(dataSetItems, selectedProject.id)
            .then((response) => {
                const result = response.data;
                if (result) {
                    replaceTestResultItem(result.items[0])
                }
            })
            .catch((error) => {
                showError(error);
            });
    };

    const updateTestResult = (testResult) => {
        dataSetService
            .updateTestResult(testResult, selectedProject.id)
            .then((response) => {
                const result = response.data;
                if (result) {
                    replaceTestResultItem(result.items[0])
                }
            })
            .catch((error) => {
                showError(error);
            });
    };

    const replaceTestResultItem = (item) => {
        const index = testResults[0].items.findIndex(it => it.id === item.id)
        if (index >= 0) {
            testResults[0].items[index] = item;
            setTestResults([...testResults])
        }
    }

    const overrideResponseIds = (items) => {
        const responseIds = items.flatMap(it => (
            {
                dataSetItemId: it.dataSetItem.id,
                testResultItemId: it.id,
                overriddenResponseId: it.answers.reduce(function (prevValue, currentValue) {
                    return currentValue.answerId ? currentValue.answerId : null;
                }, '')
            }
        ))
            .filter(it => it.overriddenResponseId && it.overriddenResponseId.length > 0)
        if (responseIds.length > 0) {
            overrideDataSetValues({items: responseIds})
        }
    }

    const overrideDataSetIntents = (items) => {
        const intents = items.filter(it => it.intents.length > 0).map(it => (
            {
                dataSetItemId: it.dataSetItem.id,
                testResultItemId: it.id,
                overriddenIntent: it.intents[0].intent
            }
        ))
        overrideDataSetValues({items: intents})
    }

    const overrideDataSetValues = (dataSetItems) => {
        dataSetService.updateDataSet(dataSetItems,selectedProject.id)
            .then(() => {
                getHistoricalTestResults();
            }).catch(error => {
            showError(error)
        });
    }


    return (
        <Paper
            variant="outlined"
            sx={{
                border: "0",
                borderRadius: "20px",
                flex: "1"
            }}>
            {status ? <Alert/> : null}
            <Grid container alignItems="center" justifyContent="right" sx={{p: 2}}>
                <Toolbar
                    setTestResults={setTestResults}
                    setTestReport={setTestReport}
                    setOpenProgressDialog={setOpenProgressDialog}
                    openProgressDialog={openProgressDialog}
                    setProgress={setProgress}
                    setItemsSize={setItemsSize}
                    conflictsOnly={conflictsOnly}
                    setConflictsOnly={setConflictsOnly}
                    itemsSize={itemsSize}
                    totalItemsCount={totalCount}
                    infoMessages={infoMessages}
                    setInfoMessages={setInfoMessages}
                />
                <TblContainer>
                    <TableBody>
                        <TableRow>
                            <TableCell sx={{border: 0}}>
                                {!dataFetched ?
                                    <React.Fragment>
                                        <Typography sx={{m: 5}} align="center"><CircularProgress/></Typography>
                                    </React.Fragment> :
                                    (dataFetched && totalCount === 0) ?
                                        <React.Fragment>
                                            <Typography sx={{m: 5}} align="center">Nebyly nalezeny žádné
                                                výsledky</Typography>
                                        </React.Fragment> :
                                        testResults.map((result, resultIndex) => (
                                            <TblContainer key={resultIndex} sx={{
                                                display: testResults.length === 1 ? 'table' : 'inherit',
                                                width: testResults.length === 1 ? '100%' : 'max-content'
                                            }}>
                                                <TblHead
                                                    index={resultIndex}
                                                    test={result}
                                                    overrideResponseIds={() => overrideResponseIds(result.items)}
                                                    overrideDataSetIntents={() => overrideDataSetIntents(result.items)}/>
                                                {result.items.map(item => (
                                                    <TableBody>
                                                        <TableRow key={item.id}>
                                                            {resultIndex === 0 ? (
                                                                <TableCell
                                                                    ref={cellElementRef}
                                                                    style={{verticalAlign: "top"}}
                                                                    sx={{
                                                                        borderRight: "1px solid rgba(224, 224, 224, 1)",
                                                                        minWidth: '200px'
                                                                    }}>
                                                                    <Typography
                                                                        variant="body2"
                                                                        className="question">
                                                                        {item.dataSetItem.question}
                                                                    </Typography>
                                                                </TableCell>
                                                            ) : null}
                                                            <TableCell
                                                                ref={cellElementRef}
                                                                style={{verticalAlign: "top", position: "relative"}}>
                                                                {item.intents
                                                                    .slice(0, 2)
                                                                    .map((it, intentIndex) =>
                                                                        intentIndex === 0 && it.intent ? (
                                                                            <IntentOnHover
                                                                                isHoverable={resultIndex === 0}
                                                                                dataSetItem={item.dataSetItem}
                                                                                dataSetIntent={item.dataSetIntent}
                                                                                intentItem={it}
                                                                                id={item.id}
                                                                                content={formattingUtils.formatIntent(it.intent.trim())}
                                                                                updateDataSet={updateDataSet}
                                                                                updateTestResult={updateTestResult}
                                                                            />
                                                                        ) : <Typography
                                                                            key={item.id}
                                                                            variant="body2"
                                                                            sx={{marginBottom: '20px'}}
                                                                        >{formattingUtils.formatIntent(it.intent)}</Typography>
                                                                    )}
                                                                <br/>
                                                                <Typography
                                                                    key={item.id}
                                                                    variant="body2"
                                                                    style={{
                                                                        color: "#58A813",
                                                                        width: "max-content",
                                                                        position: "absolute",
                                                                        bottom: 10
                                                                    }}>
                                                                    {resultIndex === 0
                                                                        ? formattingUtils.formatIntent(
                                                                            item.dataSetItem.overriddenIntent ??
                                                                            item.dataSetItem.intent
                                                                        )
                                                                        : formattingUtils.formatIntent(item.dataSetIntent)}
                                                                    {resultIndex === 0 &&
                                                                    !item.dataSetItem.intent &&
                                                                    item.intents.length > 0 &&
                                                                    item.intents[0].intent &&
                                                                    UIUtils.isIntentSettable(item.intents[0].intent, item.dataSetItem) === false ?
                                                                        <IconButton
                                                                            onClick={() => setDataSetIntent(item)}
                                                                            component="span">
                                                                            <ControlPoint/>
                                                                        </IconButton> : null}
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell
                                                                ref={cellElementRef}
                                                                style={{verticalAlign: "top"}}
                                                                align="center"
                                                            >
                                                                {item.intents.slice(0, 2).map((it, intentIndex) =>
                                                                    <Intent
                                                                        firstIntent={item.intents[0]}
                                                                        resultItemIndex={resultIndex}
                                                                        intentIndex={intentIndex}
                                                                        intentItem={it}
                                                                        resultItem={item}
                                                                        updateTestResult={updateTestResult}/>
                                                                )}
                                                            </TableCell>
                                                            <TableCell
                                                                ref={cellElementRef}
                                                                style={{verticalAlign: "top", position: "relative"}}>
                                                                {item.answers
                                                                    .slice(0, 1)
                                                                    .map((it, answerIndex) =>
                                                                        (answerIndex === 0 &&
                                                                            it.answerId)
                                                                            ?
                                                                            <ResponseOnHover
                                                                                isHoverable={resultIndex === 0}
                                                                                dataSetItem={item.dataSetItem}
                                                                                item={item}
                                                                                content={resultIndex === 0 ? `${it.answerId}: ${it.answer}` : `${it.answerId}`}
                                                                                answerId={it.answerId}
                                                                                id={item.id}
                                                                                updateDataSet={updateDataSet}
                                                                                updateTestResult={updateTestResult}
                                                                            />
                                                                            : <Typography
                                                                                key={item.id}
                                                                                variant="body2"
                                                                                sx={{
                                                                                    marginBottom: '20px',
                                                                                    background: item.dataSetItem.responseId ? 'rgba(211, 47, 47, 0.5)': null
                                                                                }}
                                                                            >{resultIndex === 0 ? `${it.answerId}: ${it.answer}` : `${it.answerId}`}</Typography>
                                                                    )}
                                                                <br/>
                                                                <Typography
                                                                    variant="body2"
                                                                    style={{
                                                                        color: "#58A813",
                                                                        width: "max-content",
                                                                        position: "absolute",
                                                                        bottom: 10
                                                                    }}>
                                                                    {resultIndex === 0
                                                                        ? item.dataSetItem.overriddenResponseId ?? item.dataSetItem.responseId ?? UND_DIALOGUE
                                                                        : item.dataSetResponseId ?? UND_DIALOGUE}
                                                                    {resultIndex === 0 &&
                                                                    !item.dataSetItem.responseId &&
                                                                    item.answers.length > 0 &&
                                                                    item.answers[0].answerId &&
                                                                    UIUtils.isResponseIdSettable(item.answers[0].answerId, item.dataSetItem) === false ?
                                                                        <IconButton
                                                                            onClick={() => setDataSetResponseId(item)}
                                                                            component="span">
                                                                            <ControlPoint/>
                                                                        </IconButton> : null}
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell
                                                                sx={{
                                                                    borderRight: "1px solid rgba(224, 224, 224, 1)",
                                                                    width: '50px'
                                                                }}
                                                            ></TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                ))}
                                            </TblContainer>
                                        ))}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </TblContainer>
                <TblPagination/>
            </Grid>
            <TestResultsDialog
                open={openResultsDialog}
                setOpen={setOpenResultsDialog}
                report={testReport}
                infoMessages={infoMessages}
                progress={progress}
                itemsSize={itemsSize}/>
            <ProgressDialog
                open={openProgressDialog}
                setOpen={setOpenProgressDialog}
                progress={progress}
                itemsSize={itemsSize}
                infoMessages={infoMessages}
                title="Test datové sady"
                subtitle={"Probíhá testování datové sady"}/>
        </Paper>
    );
};

export default SetsTesting;