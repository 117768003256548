import React, {useContext, useEffect} from "react";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {FormHelperText} from "@mui/material";
import * as dataSetService from "../../services/dataSetService";
import * as projectService from "../../services/projectService";
import useAlert from "../hooks/useAlert";
import ProjectContext from "../../context/project-context";

const StyledSelect = (props) => {
    return (
        <Select
            sx={{
                backgroundColor: 'white',
                width: '190px',
            }}
            MenuProps={{
                disableScrollLock: true,
            }}
            {...props}
        >
            {props.children}
        </Select>
    );
};

const SelectOptions = (props) => {
    const {Alert, status, showError} = useAlert();
    const {
        dataSets,
        selectedProject,
        clients,
        setClients,
        setDataSets,
        selectedClient,
        selectedDataSet,
        setSelectedClient,
        setSelectedDataSet,
    } = useContext(ProjectContext);

    useEffect(() => {
        getClients()
        //eslint-disable-next-line
    }, [selectedProject]);

    useEffect(() => {
        getDataSets();
        //eslint-disable-next-line
    }, [selectedProject]);

    useEffect(() => {
        if (dataSets.length === 0) {
            getDataSets();
        }
        //eslint-disable-next-line
    }, []);
    const getDataSets = () => {
        if (selectedProject)
            dataSetService
                .getAll(selectedProject.id, 0, 1000)
                .then((response) => {
                    const result = response.data;
                    if (result) setDataSets(result.items);
                })
                .catch((error) => {
                    setDataSets([])
                    showError(error);
                });
    };

    const getClients = () => {
        projectService
            .getClients(selectedProject.id)
            .then((response) => {
                const result = response.data;
                if (result) setClients(result);
            })
            .catch((error) => {
                showError(error);
            });
    };

    return (
        <React.Fragment>
            {status ? <Alert/> : null}
            <FormControl
                {...(props.fullWidth && {fullWidth: true})}
                size="small"
                sx={{m: 1}}>
                <InputLabel id="set">Sada</InputLabel>
                <StyledSelect
                    id="set"
                    label="Sada"
                    value={selectedDataSet ?? ""}
                    {...(props.errors.dataSet && {error: true})}
                    onChange={e => setSelectedDataSet(e.target.value)}
                >
                    {dataSets && dataSets.map(dataSet =>
                        <MenuItem
                            key={dataSet.id} value={dataSet.id}>{dataSet.name}</MenuItem>
                    )}
                </StyledSelect>
                {props.errors.dataSet && <FormHelperText sx={{color: 'red'}}>{props.errors.dataSet}</FormHelperText>}
            </FormControl>
            <FormControl
                {...(props.fullWidth && {fullWidth: true})}
                size="small"
                sx={{m: 1}}>
                <InputLabel id="client">Klient</InputLabel>
                <StyledSelect
                    id="client"
                    label="Klienty"
                    value={selectedClient ?? ''}
                    {...(props.errors.client && {error: true})}
                    onChange={e => setSelectedClient(e.target.value)}>
                    {clients && clients.map(client =>
                        <MenuItem
                            key={client.id} value={client.id}>{client.name}</MenuItem>
                    )}
                </StyledSelect>
                {props.errors.client && <FormHelperText sx={{color: 'red'}}>{props.errors.client}</FormHelperText>}
            </FormControl>
        </React.Fragment>
    );
}

export default React.memo(SelectOptions);
