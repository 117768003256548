import React, {useContext, useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import {Checkbox, FormControlLabel, Grid, styled, Switch, Typography} from '@mui/material';
import SelectOptions from '../controls/SelectOptions';
import ProjectContext from '../../context/project-context';
import * as dataSetService from "../../services/dataSetService";
import useAlert from '../hooks/useAlert';
import {v4 as uuidv4} from 'uuid';
import {GetApp} from "@mui/icons-material";
import MaterialPopover from "../controls/Popover";
import TestsExport from "../TestsExport";
import ConfirmDialog from "../dialogues/ConfirmDialog";
import {axiosClient} from "../../api/TestingFoxie";

const StyledButton = styled(Button)(() => ({
    borderRadius: "5px",
    backgroundColor: "#58A813",
    textAlign: "center",
}));

const Toolbar = (props) => {
    const [errors, setErrors] = useState('');
    const [keepSession, setKeepSession] = useState(false);
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const containerRef = React.useRef();
    const {
        setTestReport,
        setOpenProgressDialog,
        setConflictsOnly,
        setProgress,
        openProgressDialog,
        itemsSize,
        setItemsSize,
        totalItemsCount,
        infoMessages
    } = props
    const {
        selectedProject,
        selectedClient,
        selectedDataSet,
    } = useContext(ProjectContext);
    const {Alert, status, showError, handleAlertClose} = useAlert();

    useEffect(() => {
        if (openProgressDialog === false) {
            setProgress(0);
            setItemsSize(0);
        }
        //eslint-disable-next-line
    }, [openProgressDialog]);

    const handleFilterOption = (event) => {
        const name = event.target.name;
        if (name === "conflicstOnly") {
            setConflictsOnly(event.target.checked);
        }
    };

    const runTest = () => {
        handleAlertClose();
        const wrongValues = validate();
        let emitterId = uuidv4();
        const eventSource = new EventSource(`${axiosClient.defaults.baseURL}/api/v1/tests/progress-stream?emitterId=${emitterId}`);

        eventSource.addEventListener("TEST_PROGRESS", (event) => {
            let progress = JSON.parse(event.data);
            console.log(`Progress: ${progress}`);
            setProgress(progress);
            if (progress === itemsSize) {
                eventSource.close();
                console.log("SSE closed");
            }
        });

        eventSource.addEventListener("TEST_SIZE", (event) => {
            let size = JSON.parse(event.data);
            console.log(`Test size: ${size}`);
            setItemsSize(size);
        });

        eventSource.addEventListener("TEST_INFO_MESSAGE", (event) => {
            let infoMessage = event.data;
            infoMessages.push(infoMessage);
        });

        eventSource.onerror = (event) => {
            console.log(event.target.readyState);
            if (event.target.readyState === EventSource.CLOSED)
                console.log("SSE closed (" + event.target.readyState + ")");
            else
                console.log("connection error", event.type);
            eventSource.close();
        };

        eventSource.onopen = () => {
            console.log("connection opened");
        };

        eventSource.addEventListener("EMITTER_STATUS", () => {
            if (Object.values(wrongValues).every(x => x === '')) {
                setErrors('');
                setOpenProgressDialog(true);
                dataSetService
                    .runTest(
                        selectedDataSet,
                        selectedProject.id,
                        selectedClient,
                        keepSession,
                        emitterId
                    )
                    .then((response) => {
                        setTestReport(response.data);
                    })
                    .catch((error) => {
                        showError(error);
                    })
                    .finally(() => {
                        setOpenProgressDialog(false);
                        eventSource.close();
                    });
            } else {
                setErrors(wrongValues);
            }
        });

    };

    const validate = () => {
        let temp = {};
        temp.client = selectedClient ? "" : "Klient je vyžadován";
        temp.dataSet = selectedDataSet ? "" : "Datová sada je vyžadována";
        return temp;
    };

    return (
        <div ref={containerRef}>
            {status ? <Alert/> : null}
            <Grid container alignItems="baseline" sx={{p: 2}}>
                <Grid item container justifyContent="left" alignItems="center" xs={6}>
                    <SelectOptions
                        errors={errors}
                    />
                </Grid>
                <Grid item container justifyContent="left" alignItems="left" xs={2}>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={keepSession}
                                onChange={e => setKeepSession(e.target.checked)}
                                defaultChecked
                            />} label="Keep session"/>
                </Grid>
                <Grid item container alignItems="baseline" justifyContent="right" xs={4}>
                    <StyledButton
                        startIcon={<AddIcon/>}
                        variant="contained"
                        onClick={() => {
                            if (totalItemsCount >= 100)
                                setOpenConfirmDialog(true)
                            else
                                runTest();
                        }}>
                        Spustit test
                    </StyledButton>
                </Grid>
                <Grid item container alignItems="baseline" spacing={2} xs={8}>
                    <Grid item>
                        <Typography>
                            <Checkbox
                                name="conflicstOnly"
                                onChange={(event) => {
                                    handleFilterOption(event);
                                }}
                            />
                            Zobrazit výsledky s konflikty
                        </Typography>
                    </Grid>
                    <Grid item>
                        <MaterialPopover
                            container={containerRef.current}
                            content={
                                <Button startIcon={<GetApp/>}>
                                    Export výsledků
                                </Button>
                            }>
                            <TestsExport
                                container={containerRef.current}
                                dataSet={selectedDataSet}
                                project={selectedProject}
                                client={selectedClient}
                                showError={showError}
                            />
                        </MaterialPopover>
                    </Grid>
                </Grid>
            </Grid>
            <ConfirmDialog
                open={openConfirmDialog}
                setOpen={setOpenConfirmDialog}
                title={`Testovací sáda obsahuje ${totalItemsCount} otázek`}
                subtitle={"Opravdu chcete pokračovat?"}
                confirmAction={() => runTest()}
            />
        </div>
    )
};

export default Toolbar;